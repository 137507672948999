import { useCallback, useEffect, useState } from "react"
import { notification } from "antd"
import "./styles.scss"
import moment from "moment"
import { useMutateData } from "../../../utils/hooks"
import { DynamicForm } from "../DynamicForm"
import { labOrderFormUrl, getOrderOptionsApi, scheduleSampleUrlApi } from "../../../api/endpoints"
import { dateFields } from "./constants"

const ScheduleSamplePickupForm = (props) => {
  const {
    programId,
    patientProgramApplicationId = null,
    record = null,
    toggleScheduleSamplePickupFormFlag = null,
    orderId = "",
  } = props
  const [callApi] = useMutateData()
  const [formFields, setFormFields] = useState({})
  const [optionsData, setOptionsData] = useState({})
  const [toastMessage, contextHolder] = notification.useNotification()
  const [defaultValues, setDefaultValues] = useState({})
  const [allSelectedDropdownValues, setAllSelectedDropdownValues] = useState({})
  const setSelectedDropdownValue = ({ name, value }) => {
    if (name && value) {
      const newSelectedDropdownValues = {
        ...allSelectedDropdownValues,
        [name]: value,
      }
      if (JSON.stringify(newSelectedDropdownValues) !== JSON.stringify(allSelectedDropdownValues)) {
        setAllSelectedDropdownValues(newSelectedDropdownValues)
      }
    }
  }
  const fetchOptionsBasedOnUserSelection = (name, value) => {
    setSelectedDropdownValue({ name, value })
  }
  const fetchOptions = useCallback(
    ({ key = "" }) =>
      new Promise((resolve) => {
        callApi({
          url: getOrderOptionsApi(),
          method: "POST",
          payload: {
            patient_program_application_id: patientProgramApplicationId,
            options_key: key,
            program_id: programId,
          },
        })
          .then(({ data = [] }) => {
            resolve(data)
          })
          .catch(() => resolve([]))
      }),
    [callApi, programId]
  )
  const getAllDropdownData = useCallback(
    ({ dropdownKeys = [] }) => {
      const allDropdownOptions = dropdownKeys.map((key) => fetchOptions({ key }))
      Promise.all(allDropdownOptions).then((values) => {
        const dropdownValues = {}
        dropdownKeys.forEach((key, i) => {
          dropdownValues[key] = values[i]
        })
        setOptionsData(dropdownValues)
      })
    },
    [fetchOptions]
  )
  const formatAndSaveDefaultValues = (data) => {
    let defValues = null
    defValues = data?.defaultValues || {}
    const flattenedSavedData = Object.values(defValues).reduce(
      (acc, section) => ({
        ...acc,
        ...section,
      }),
      {}
    )
    setAllSelectedDropdownValues(flattenedSavedData)
    if (defValues?.order_details?.sample_pickup_date) {
      defValues.order_details.sample_pickup_date = moment(defValues.order_details.sample_pickup_date)
    }
    setDefaultValues(defValues)
  }
  const fetchFormFields = async () => {
    try {
      const { data } = await callApi({
        url: labOrderFormUrl(),
        payload: { program_id: programId, type: "SCHEDULE_SAMPLE_PICKUP", order_id: orderId },
        method: "POST",
      })
      formatAndSaveDefaultValues(data)
      setFormFields(data?.requirements)
      const dropdowns = data?.dropdowns || []
      getAllDropdownData({ dropdownKeys: dropdowns })
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    fetchFormFields()
  }, [])

  const preparePayload = (formDataObj) => {
    let processedDetails = { ...formDataObj }
    Object.keys(processedDetails).forEach((key) => {
      const value = processedDetails[key]
      if (dateFields.includes(key)) {
        processedDetails[key] = value?.format("YYYY-MM-DD")
      } else {
        processedDetails[key] = value
      }
    })

    processedDetails = {
      order_details: {
        order_id: record?.id || record?.order_id,
        ...processedDetails,
      },
      program_id: programId,
    }
    return processedDetails
  }

  const scheduleSample = async (payload) => {
    try {
      const { data = {} } = await callApi({
        url: scheduleSampleUrlApi,
        method: "POST",
        payload,
      })
      const { message = "" } = data
      const isSuccess = message.includes("scheduled")

      if (isSuccess) {
        toastMessage.success({
          key: "scheduleSampleSuccess",
          message: "Success",
          description: data?.data?.message || "Sample pickup scheduled",
        })
        setTimeout(() => {
          toggleScheduleSamplePickupFormFlag(false)
        }, 2000)
      } else {
        toastMessage.error({
          key: "scheduleSampleSuccess",
          message: "Error",
          description: data?.error?.message || "Error in scheduling sample test",
        })
      }
    } catch (apiError) {
      console.log("Scheduling sample error", apiError)
    }
  }

  const scheduleSamplePickupHandler = (formData) => {
    const keys = Object.keys(formData)
    const payload = preparePayload(formData[keys[0]])
    scheduleSample(payload)
  }

  return (
    <>
      {contextHolder}
      <div className="sample-pickup-form-page-container">
        <div className="sample-pickup-form-container">
          <DynamicForm
            pageTitle="Schedule Sample Pickup"
            formDetails={formFields}
            saveCallback={scheduleSamplePickupHandler}
            optionsData={optionsData || {}}
            formType="labOrderForm"
            savedData={defaultValues}
            fetchOptionsBasedOnUserSelection={fetchOptionsBasedOnUserSelection}
            stepsFilterCustomSteps={allSelectedDropdownValues}
          />
        </div>
      </div>
    </>
  )
}

export default ScheduleSamplePickupForm
