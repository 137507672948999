export const getOrderFormKey = ({ orderType = "", action = "" }) => {
  let type = ""
  if (action === "CREATE") {
    if (orderType === "PAID") {
      type = "CREATE_PAID_ORDER"
    } else if (orderType === "FOC") {
      type = "CREATE_FOC_ORDER"
    }
  } else if (action === "DIGITIZE_PAID_ORDER") {
    type = "DIGITIZE_ORDER_FORM"
  } else {
    type = `${action}_FORM`
  }
  return type
}

export const getFetchFormPayload = ({ patientId = "", programId = "", action = "", orderType = "" }) => {
  const type = getOrderFormKey({ orderType, action })
  const payload = {
    patient_id: patientId,
    program_id: programId,
    type,
  }
  return payload
}
