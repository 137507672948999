export const CLOSE_FOC_ORDER_FORM = {
  sections: [
    {
      name: "details",
      label: "",
      steps: [
        {
          step_name: "zero_order_invoice",
          step_name_text: "Zero Order Invoice",
          is_mandatory: true,
          field_type: "file_upload",
        },
        {
          step_name: "zero_value_invoice_date",
          step_name_text: "Zero Value Invoice Date",
          is_mandatory: true,
          field_type: "date",
        },
        {
          step_name: "zero_value_invoice_number",
          step_name_text: "Zero Value Invoice Number",
          is_mandatory: true,
          field_type: "number",
        },
        {
          step_name: "closure_date",
          step_name_text: "Closure Date",
          is_mandatory: true,
          field_type: "date",
        },
      ],
    },
  ],
}
