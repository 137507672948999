import { Button, Modal, Form, notification } from "antd"
import { useState } from "react"
import { DEFAULT_MODAL_WIDTH } from "../../../../constants/common"
import "./styles.scss"
import { CreateOrderForm } from "../../CreateOrderForm"
import { useMutateData } from "../../../../utils/hooks"
import { digitiseOrderUrl } from "../../../../api/endpoints"

export const OrderDigitisation = ({
  action,
  orderId,
  patientId,
  programId,
  programApplicationId,
  afterActionCallback = () => {},
}) => {
  const [showModal, setShowModal] = useState(false)
  const [form] = Form.useForm()
  const [apiCall] = useMutateData()
  const [toastMessage, contextHolder] = notification.useNotification()
  const openModal = () => {
    form.resetFields()
    setShowModal(true)
  }
  const hideModal = () => {
    setShowModal(false)
  }
  const onModalSubmit = () => {
    form.submit()
  }
  const getDigitiseButton = () => (
    <Button className="orderTableActionButtons" onClick={openModal}>
      Digitize
    </Button>
  )
  const digitiseCallback = async (digitiseData) => {
    const { data = {} } = await apiCall({
      url: digitiseOrderUrl,
      method: "POST",
      payload: {
        program_id: programId,
        order_id: orderId,
        ...digitiseData,
      },
    })
    const { error = false, message = "" } = data
    if (!error) {
      toastMessage.success({
        key: "orderDigitisationError",
        message: "Success",
        description: message,
      })
      hideModal()
    }
    afterActionCallback()
  }
  const digitisationModal = () => {
    const width = DEFAULT_MODAL_WIDTH
    return (
      <Modal
        width={width}
        bodyStyle={{ height: "85vh" }}
        open={showModal}
        centered
        onOk={onModalSubmit}
        onCancel={hideModal}
        okText="Digitize"
        cancelText="Cancel"
        className="order-digitisation-modal"
      >
        {contextHolder}
        <CreateOrderForm
          type="PAID"
          programId={programId}
          patientId={patientId}
          patient_program_application_id={programApplicationId}
          closeOrderForm={hideModal}
          action={action}
          orderId={orderId}
          showFormFooter={false}
          formSectionBuilderWidth={12}
          customForm={form}
          customSaveCallback={digitiseCallback}
          formName={`Digitize Order ID : ${orderId}`}
        />
      </Modal>
    )
  }
  return (
    <>
      {getDigitiseButton()}
      {showModal && digitisationModal()}
    </>
  )
}
