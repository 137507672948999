import moment from "moment"
/**
 * @description Function to capitalize passed string
 * @param {string} str string with at least one character.
 * @returns {string} Capitalized string.
 */

export const capitalize = (str) => {
  if (!str || typeof str !== "string") return str
  return `${str[0].toUpperCase()}${str.substring(1)}`
}

/**
 * @description Function to replace empty string in object with null value
 * @param {{}} data data object.
 * @returns {{}} data object with null in place of empty string
 */
export const replaceStringWithNull = (data) => {
  const formData = data
  const keys = Object.keys(formData)
  keys.forEach((key) => {
    if (typeof formData[key] === "string" || formData[key] instanceof String) {
      const value = formData[key].trim()
      formData[key] = value === "" ? null : value
    }
  })
  return formData
}

export const mbToBytes = (mb) => {
  const bytes = mb * 1024 * 1024
  return bytes
}

export const validateFileSizeType = ({ fileObj, maxFileSizeInMB = 0, allowedFileExtensions = [] }) => {
  const { type = "", size = 0 } = fileObj
  const maxFileSizeInBytes = mbToBytes(maxFileSizeInMB)
  const isValidSize = size <= maxFileSizeInBytes
  const isValidType = allowedFileExtensions.includes(type)
  const filesString = allowedFileExtensions.join(",")
  return {
    isValid: isValidSize && isValidType,
    message: `File must be less than ${maxFileSizeInMB}mb and of type(s) [${filesString}]`,
  }
}

/**
 * @description Function to format date to dd-mm-yyyy
 * @param {{}}  date object.
 * @returns {{}} string
 */
export const formatDate = (dateVal) => moment(new Date(dateVal)).utc(false).format("DD-MM-YYYY")

export const formatDateTime = (dateVal) => moment(new Date(dateVal)).utc(false).format("DD-MM-YYYY h:mm a")

export const formatTime = (dateVal) => moment(new Date(dateVal)).utc(false).format("HH:mm")

export const formatTimeAsAmPm = (dateVal) => moment(new Date(dateVal)).utc(false).format("hh:mm A")

export const getUser = (state) => state?.user?.user || null

export const getUserName = (state) => {
  const user = getUser(state) || {}
  return user?.name || ""
}

export const getSelectedApplication = (state) => {
  const { selectedApplication = {} } = getUser(state) || {}
  return selectedApplication
}

export const getUserRole = (state) => {
  const { role = "" } = getSelectedApplication(state)
  return role
}

export const getApplicationLogo = (state) => {
  const selectedApplication = getSelectedApplication(state)
  return selectedApplication?.logo_url || ""
}

export const getCurrentSubdomain = (state) => {
  const selectedApplication = getSelectedApplication(state)
  return selectedApplication?.subdomain || ""
}

export const roleLabels = {
  pap_executive: "PAP Executive",
  pap_manager: "PAP Manager",
  phlebotomist: "Phlebotomist",
  field_counsellor: "Field Counsellor",
  field_counsellor_manager: "Field Counsellor Manager",
  financial_counsellor: "Financial Counsellor",
  wellbeing_counsellor: "Wellbeing Counsellor",
  disease_counsellor: "Disease Counsellor",
}

export const getRoleLabel = (roleAppObj) => {
  const { name = "", role = "" } = roleAppObj
  const roleInLower = role?.toLowerCase() || ""
  const roleLabel = roleInLower && roleLabels[roleInLower] ? roleLabels[roleInLower] : ""
  return `${name} ${roleLabel}`
}

export const hasAccessToPhleboPortal = (state) => {
  const selectedApplication = getSelectedApplication(state)
  const { role = "" } = selectedApplication
  return role.toLowerCase() === "phlebotomist"
}

export const getCurrentApplicationRoleLabel = (state) => {
  const selectedApplication = getSelectedApplication(state)
  return getRoleLabel(selectedApplication)
}

export const getUserId = (state) => {
  const userObj = getUser(state)
  return userObj?.user_id
}
export const getAllApplicationRoles = (state) => {
  const userObj = getUser(state)
  return userObj?.applications || []
}

export const getPermissions = (state) => {
  const { permissions = {} } = getUser(state)
  return permissions
}

export const enableCreateAe = (state) => {
  const permissions = getPermissions(state)
  return permissions?.CREATE_AE || false
}

export const enableCloseAe = (state) => {
  const permissions = getPermissions(state)
  return permissions?.CLOSE_AE || false
}

export const enableApproveAe = (state) => {
  const permissions = getPermissions(state)
  return permissions?.APPROVE_AE || false
}

export const enableRejectAe = (state) => {
  const permissions = getPermissions(state)
  return permissions?.REJECT_AE || false
}

export const enableEditAe = (state) => {
  const permissions = getPermissions(state)
  return permissions?.EDIT_AE || false
}

export const enableProcessAe = (state) => {
  const enableApprove = enableApproveAe(state)
  const enableReject = enableRejectAe(state)
  const enavleClose = enableCloseAe(state)
  return enableApprove || enableReject || enavleClose
}

export const isEditAePresentAfterApproval = (state) => {
  const permissions = getPermissions(state)
  return permissions?.EDIT_AE_AFTER_APPROVAL || false
}

export const isSendAeAllowed = (state) => {
  const permissions = getPermissions(state)
  return permissions?.SEND_AE || false
}

/**
 * @description Function to check if the given value is in ISO format
 * @param {{}}  date object.
 * @returns {{}} boolean
 */
export const isISOFormat = (value) => {
  const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?(?:Z|[+-]\d{2}:\d{2})?$/
  return isoDateFormat.test(value)
}

export const canAccessFcModules = (state) => {
  const { role = " " } = getSelectedApplication(state)
  return role === "FIELD_COUNSELLOR" || role === "FIELD_COUNSELLOR_MANAGER"
}

export const isDoctorConsentEnabled = (state) => {
  const permissions = getPermissions(state)
  return permissions?.DOCTOR_CONSENT || false
}

export const allowCreatePatient = (state) => {
  const permissions = getPermissions(state)
  return permissions?.CREATE_PATIENT || false
}

export const hasAccessToAeModule = (state) => {
  const permissions = getPermissions(state)
  return permissions?.MODULE_AE || false
}

export const isPvModuleEnabled = (state) => {
  const permissions = getPermissions(state)
  return permissions?.MODULE_PV || false
}

export const isFcModuleEnabled = (state) => {
  const permissions = getPermissions(state)
  return permissions?.MODULE_FC || false
}

export const isProgramsTabEnabled = (state) => {
  const permissions = getPermissions(state)
  return permissions?.ENABLE_PROGRAMS_TAB_IN_PATIENT_PROFILE || false
}

export const isChangePatientStatusAlowed = (state) => {
  const permissions = getPermissions(state)
  return permissions?.CHANGE_PATIENT_STATUS || false
}

export const isEkycModuleEnabled = (state) => {
  const permissions = getPermissions(state)
  return permissions?.MODULE_EKYC || false
}

export const isEkycTagsEnabled = (state) => {
  const permissions = getPermissions(state)
  return permissions?.EKYC_TAGS || false
}

export const isTagFcEnabled = (state) => {
  const permissions = getPermissions(state)
  return permissions?.TAG_FC || false
}

export const isCreatePatientInteractionAllowed = (state) => {
  const permissions = getPermissions(state)
  return permissions?.CREATE_PATIENT_INTERACTION || false
}

export const isTriggerProfileKycAllowed = (state) => {
  const permissions = getPermissions(state)
  return permissions?.TRIGGER_PROFILE_KYC || false
}

export const isTriggerFocKycAllowed = (state) => {
  const permissions = getPermissions(state)
  return permissions?.TRIGGER_FOC_KYC || false
}

export const isApproveKycAllowed = (state) => {
  const permissions = getPermissions(state)
  return permissions?.APPROVE_EKYC || false
}

export const isCancelKycAllowed = (state) => {
  const permissions = getPermissions(state)
  return permissions?.CANCEL_EKYC || false
}

export const isSchedulePvAllowed = (state) => {
  const permissions = getPermissions(state)
  return permissions?.CREATE_PV || false
}

export const isEditPvAllowed = (state) => {
  const permissions = getPermissions(state)
  return permissions?.EDIT_PV || false
}

export const isChangePvStatusAllowed = (state) => {
  const permissions = getPermissions(state)
  return permissions?.CHANGE_PV_STATUS || false
}

export const isCreateTaskAllowed = (state) => {
  const permissions = getPermissions(state)
  return permissions?.CREATE_MANUAL_TASK || false
}

export const isEditTaskAllowed = (state) => {
  const permissions = getPermissions(state)
  return permissions?.EDIT_MANUAL_TASK || false
}

export const isChangeTaskStatusAllowed = (state) => {
  const permissions = getPermissions(state)
  return permissions?.CHANGE_TASK_STATUS || false
}

export const isPaidOrderTableToBeShown = (state) => {
  const permissions = getPermissions(state)
  return permissions?.PAID_ORDER_DASHBOARD || false
}

export const isFocOrderTableToBeShown = (state) => {
  const permissions = getPermissions(state)
  return permissions?.FOC_ORDER_DASHBOARD || false
}

export const isAeTableToBeShown = (state) => {
  const permissions = getPermissions(state)
  return permissions?.AE_DASHBOARD || false
}

export const canBookLabTest = (state) => {
  const permissions = getPermissions(state)
  return permissions?.BOOK_LAB_ORDER || false
}

export const isLabTableToBeShown = (state) => {
  const permissions = getPermissions(state)
  return permissions?.LAB_ORDERS_DASHBOARD || false
}

export const canApproveLabOrder = (state) => {
  const permissions = getPermissions(state)
  return permissions?.APPROVE_LAB_ORDER || false
}

export const canRejectLabOrder = (state) => {
  const permissions = getPermissions(state)
  return permissions?.REJECT_LAB_ORDER || false
}

export const canScheduleSamplePickup = (state) => {
  const permissions = getPermissions(state)
  return permissions?.SCHEDULE_SAMPLE_PICKUP || false
}

export const canCloseLabOrder = (state) => {
  const permissions = getPermissions(state)
  return permissions?.CLOSE_LAB_ORDER || false
}

export const canViewCallsDashboard = (state) => {
  const permissions = getPermissions(state)
  return permissions?.CALL_LOGS_DASHBOARD || false
}

export const toBeShown = (menuItemConfig) => menuItemConfig?.toBeShown || false

export const getAllSideMenuItems = (state) => {
  const currentSubdomain = getCurrentSubdomain(state)
  const permissions = getPermissions(state)
  const ALL_MENU_ITEMS = {
    PATIENT_DASHBOARD: {
      key: `/${currentSubdomain}/patient/dashboard`,
      label: "Patient Dashboard",
      toBeShown: permissions?.PATIENT_DASHBOARD || false,
    },
    CREATE_PATIENT: {
      key: `/${currentSubdomain}/patient/create`,
      label: "Add New Patient",
      toBeShown: permissions?.CREATE_PATIENT || false,
    },
    PROGRAM_DASHBOARD: {
      key: `/${currentSubdomain}/patient/program-dashboard`,
      label: "Program Dashboard",
      icon: "GroupOutlined",
      toBeShown: permissions?.PROGRAM_DASHBOARD || false,
    },
    PAID_ORDERS_DASHBOARD: {
      key: `/${currentSubdomain}/patient/orders/paid`,
      label: "Paid Orders",
      toBeShown: permissions?.PAID_ORDER_DASHBOARD || false,
    },
    FOC_ORDERS_DASHBOARD: {
      key: `/${currentSubdomain}/patient/orders/foc`,
      label: "FOC Orders",
      toBeShown: permissions?.FOC_ORDER_DASHBOARD || false,
    },
    LAB_ORDERS_DASHBOARD: {
      key: `/${currentSubdomain}/lab-orders/dashboard`,
      label: "Lab Orders ",
      toBeShown: permissions?.LAB_ORDERS_DASHBOARD || false,
    },
    DOCTORS_DASHBOARD: {
      key: `/${currentSubdomain}/master-management/doctor/all`,
      label: "Doctors",
      toBeShown: permissions?.DOCTORS_DASHBOARD || false,
    },
    HOSPITALS_DASHBOARD: {
      key: `/${currentSubdomain}/master-management/hospital/all`,
      label: "Hospitals",
      toBeShown: permissions?.HOSPITALS_DASHBOARD || false,
    },
    DISTRIBUTORS_DASHBOARD: {
      key: `/${currentSubdomain}/master-management/distributor/all`,
      label: "Distributors",
      toBeShown: permissions?.DISTRIBUTORS_DASHBOARD || false,
    },
    LABS_DASHBOARD: {
      key: `/${currentSubdomain}/master-management/lab/all`,
      label: "Labs",
      toBeShown: permissions?.LABS_DASHBOARD || false,
    },
    AE_DASHBOARD: {
      key: `/${currentSubdomain}/patient/adverse-events-dashboard`,
      label: "Adverse Events Dashboard",
      icon: "UserAddOutlined",
      toBeShown: permissions?.AE_DASHBOARD || false,
    },
    EKYC_DASHBOARD: {
      key: `/${currentSubdomain}/ekyc/dashboard`,
      label: "E-KYC Dashboard",
      icon: "VideoCameraOutlined",
      toBeShown: permissions?.EKYC_DASHBOARD || false,
    },
    CLAIM_DASHBOARD: {
      key: `/${currentSubdomain}/claims/dashboard`,
      label: "SAP Orders",
      icon: "WalletOutlined",
      toBeShown: permissions?.CLAIM_DASHBOARD || false,
    },
    COUNSELLING_DASHBOARD: {
      key: `/${currentSubdomain}/counselling/dashboard`,
      label: "Counselling Dashboard",
      icon: "TeamOutlined",
      toBeShown: permissions?.VIEW_COUNSELLING_DASHBOARD || false,
    },
    PV_DASHBOARD: {
      key: `/${currentSubdomain}/pv/dashboard`,
      label: "PV Dashboard",
      icon: "CarryOutOutlined",
      toBeShown: permissions?.PV_DASHBOARD || false,
    },
    FC_TASK_DASHBOARD: {
      key: `/${currentSubdomain}/fc/task-dashboard`,
      label: "Tasks",
      icon: "CheckCircleOutlined",
      toBeShown: permissions?.FC_TASK_DASHBOARD || false,
    },
    FC_PATIENT_INTERACTION_DASHBOARD: {
      key: `/${currentSubdomain}/fc/patient-interaction-module`,
      label: "Patient Interaction",
      icon: "UsergroupAddOutlined",
      toBeShown: permissions?.FC_PATIENT_INTERACTION_DASHBOARD || false,
    },
    FC_UPLOAD_DASHBOARD: {
      key: `/${currentSubdomain}/fc/fc-upload-dashboard`,
      label: "FC Upload",
      icon: "FileAddOutlined",
      toBeShown: permissions?.FC_UPLOAD_DASHBOARD || false,
    },
    EMAIL_DASHBOARD: {
      key: `/${currentSubdomain}/communication/email-dashboard`,
      label: "Emails",
      toBeShown: permissions?.EMAIL_DASHBOARD || false,
    },
    SMS_DASHBOARD: {
      key: `/${currentSubdomain}/communication/sms-dashboard`,
      label: "SMS",
      toBeShown: permissions?.SMS_DASHBOARD || false,
    },
    CALLS_DASHBOARD: {
      key: `/${currentSubdomain}/communication/calls-dashboard`,
      label: "Calls",
      toBeShown: canViewCallsDashboard(state),
    },
    LOGOUT: {
      key: "/logout",
      label: "Logout",
      icon: "LogoutOutlined",
      toBeShown: true,
    },
  }
  const MENU_GROUP_CONFIG = {
    PATIENT_MANAGEMENT: {
      label: "Patient Management",
      icon: "UserOutlined",
      children: [ALL_MENU_ITEMS.PATIENT_DASHBOARD, ALL_MENU_ITEMS.CREATE_PATIENT].filter(toBeShown),
      toBeShown: permissions?.MODULE_PATIENT || false,
    },
    ORDERS_DASHBOARD: {
      label: "Orders Dashboard",
      icon: "ShoppingCartOutlined",
      children: [
        ALL_MENU_ITEMS.PAID_ORDERS_DASHBOARD,
        ALL_MENU_ITEMS.FOC_ORDERS_DASHBOARD,
        ALL_MENU_ITEMS.LAB_ORDERS_DASHBOARD,
      ].filter(toBeShown),
      toBeShown: permissions?.MODULE_ORDER || false,
    },
    MASTER_MANAGEMENT: {
      label: "Master Management",
      icon: "SettingOutlined",
      children: [
        ALL_MENU_ITEMS.DOCTORS_DASHBOARD,
        ALL_MENU_ITEMS.HOSPITALS_DASHBOARD,
        ALL_MENU_ITEMS.DISTRIBUTORS_DASHBOARD,
        ALL_MENU_ITEMS.LABS_DASHBOARD,
      ].filter(toBeShown),
      toBeShown: permissions?.MODULE_MASTER_MANAGEMENT || false,
    },
    COMMUNICATION_DASHBOARD: {
      label: "Communication Dashboard",
      icon: "CommentOutlined",
      children: [
        ALL_MENU_ITEMS.EMAIL_DASHBOARD,
        ALL_MENU_ITEMS.SMS_DASHBOARD,
        ALL_MENU_ITEMS.CALLS_DASHBOARD,
      ].filter(toBeShown),
      toBeShown: permissions?.COMMUNICATION_DASHBOARD || false,
    },
    CLAIM_DASHBOARD: {
      label: "SAP Orders",
      icon: "WalletOutlined",
      children: [ALL_MENU_ITEMS.CLAIM_DASHBOARD].filter(toBeShown),
      toBeShown: permissions?.CLAIM_DASHBOARD || false,
    },
  }
  const ALL_MENU_GROUP_LIST = [
    MENU_GROUP_CONFIG.PATIENT_MANAGEMENT,
    ALL_MENU_ITEMS.PROGRAM_DASHBOARD,
    MENU_GROUP_CONFIG.ORDERS_DASHBOARD,
    ALL_MENU_ITEMS.CLAIM_DASHBOARD,
    ALL_MENU_ITEMS.COUNSELLING_DASHBOARD,
    ALL_MENU_ITEMS.EKYC_DASHBOARD,
    ALL_MENU_ITEMS.PV_DASHBOARD,
    ALL_MENU_ITEMS.FC_TASK_DASHBOARD,
    ALL_MENU_ITEMS.FC_PATIENT_INTERACTION_DASHBOARD,
    ALL_MENU_ITEMS.FC_UPLOAD_DASHBOARD,
    ALL_MENU_ITEMS.AE_DASHBOARD,
    MENU_GROUP_CONFIG.MASTER_MANAGEMENT,
    MENU_GROUP_CONFIG.COMMUNICATION_DASHBOARD,
    ALL_MENU_ITEMS.LOGOUT,
  ]
  const menuItems = ALL_MENU_GROUP_LIST.filter(toBeShown)
  return menuItems
}

export const isEditPatientAllowed = (state) => {
  const permissions = getPermissions(state)
  return permissions?.EDIT_PATIENT || false
}

export const isCreateProgramApplicationAllowed = (state) => {
  const permissions = getPermissions(state)
  return permissions?.ENROLL_INTO_PROGRAM || false
}

export const allowApproveRejectProgramApplication = (state) => {
  const permissions = getPermissions(state)
  return permissions?.APPROVE_REJECT_PROGRAM_ENROLLMENT || false
}

export const isCreatePaidOrderAllowed = (state) => {
  const permissions = getPermissions(state)
  return permissions?.CREATE_PAID_ORDER || false
}

export const isCreateFocOrderAllowed = (state) => {
  const permissions = getPermissions(state)
  return permissions?.CREATE_FOC_ORDER || false
}

export const isSuspendProgramDocumentAllowed = (state) => {
  const permissions = getPermissions(state)
  return permissions?.SUSPEND_PROGRAM_DOCUMENT || false
}

export const isEditProgramDocumentAllowed = (state) => {
  const permissions = getPermissions(state)
  return permissions?.EDIT_PROGRAM_DOCUMENT || false
}

export const isEditPrescriptionAllowed = (state) => {
  const permissions = getPermissions(state)
  return permissions?.EDIT_PRESCRIPTION || false
}

export const isEditProgramApplicationAllowedAfterApproval = (state) => {
  const permissions = getPermissions(state)
  return permissions?.EDIT_PROGRAM_APPLICATION_AFTER_APPROVAL || false
}

export const isDigitiseProgramAllowed = (state) => {
  const permissions = getPermissions(state)
  return permissions?.DIGITIZE_PROGRAM || false
}

export const isMultiSectionProgramDetailsPageEnabled = (state) => {
  const permissions = getPermissions(state)
  return permissions?.ENABLE_MULTI_SECTION_PROGRAM_DETAILS_PAGE || false
}

export const isLabOrdersEnabledForProgram = ({ permissions = [] }) =>
  permissions.includes("ENABLE_LAB_ORDERS_FOR_PROGRAM")

export const isClaimsEnabledForProgram = ({ permissions = [] }) =>
  permissions.includes("ENABLE_CLAIMS_FOR_PROGRAM")

export const isCounsellingEnabledForProgram = ({ permissions = [] }) =>
  permissions.includes("ENABLE_COUNSELLING_FOR_PROGRAM")

export const isPaidOrdersEnabledForProgram = ({ permissions = [] }) =>
  permissions.includes("ENABLE_PAID_ORDERS_FOR_PROGRAM")

export const isFocOrdersEnabledForProgram = ({ permissions = [] }) =>
  permissions.includes("ENABLE_FOC_ORDERS_FOR_PROGRAM")

export const isAeEnabledForProgram = ({ permissions = [] }) =>
  permissions.includes("ENABLE_ADVERSE_EVENTS_FOR_PROGRAM") || true
