import { APPROVE_PAID_ORDER_FORM } from "./approvePaidOrder"
import { REJECT_PAID_ORDER_FORM } from "./rejectPaidOrder"
import { CANCEL_PAID_ORDER_FORM } from "./cancelPaidOrder"
import { APPROVE_FOC_ORDER_FORM } from "./approveFocOrder"
import { REJECT_FOC_ORDER_FORM } from "./rejectFocOrder"
import { CANCEL_FOC_ORDER_FORM } from "./cancelFocOrder"
import { CLOSE_FOC_ORDER_FORM } from "./closeFocOrder"
import { APPROVE_PROGRAM_FORM } from "./approveProgram"
import { REJECT_PROGRAM_FORM } from "./rejectProgram"
import { REJECT_ICON, APPROVE_ICON } from "../../../constants/images"

export const NEXT_AVAILABLE_ACTIONS = {
  PAID_OPEN: ["APPROVE_PAID_ORDER", "REJECT_PAID_ORDER", "CANCEL_PAID_ORDER"],
  PAID_DIGITIZATION_PENDING: ["DIGITIZE_PAID_ORDER"],
  "PAID_DIGITIZATION PENDING": ["DIGITIZE_PAID_ORDER"],
  PAID_APPROVED: ["CLOSE_PAID_ORDER"],
  PAID_REJECTED: [],
  PAID_CANCELLED: [],
  FOC_OPEN: ["APPROVE_FOC_ORDER", "REJECT_FOC_ORDER", "CANCEL_FOC_ORDER"],
  FOC_DIGITIZATION_PENDING: ["DIGITIZE_FOC_ORDER"],
  FOC_APPROVED: ["CLOSE_FOC_ORDER"],
  FOC_REJECTED: [],
  FOC_CLOSED: [],
}

export const ACTIONS_BTNS_MAPPING = {
  APPROVE_MAPPED_PROGRAM: "APPROVE",
  REJECT_MAPPED_PROGRAM: "REJECT",
  APPROVE_PAID_ORDER: "APPROVE",
  CLOSE_PAID_ORDER: "CLOSE",
  DIGITIZE_PAID_ORDER: "DIGITIZE",
  REJECT_PAID_ORDER: "REJECT",
  CANCEL_PAID_ORDER: "CANCEL",
  APPROVE_FOC_ORDER: "APPROVE",
  REJECT_FOC_ORDER: "REJECT",
  CANCEL_FOC_ORDER: "CANCEL",
  CLOSE_FOC_ORDER: "CLOSE",
  DIGITIZE_FOC_ORDER: "DIGITIZE",
}

export const ACTIONS_ORDER_TYPE_MAPPING = {
  APPROVE_PAID_ORDER: "PAID",
  DIGITIZE_PAID_ORDER: "PAID",
  CLOSE_PAID_ORDER: "PAID",
  REJECT_PAID_ORDER: "PAID",
  CANCEL_PAID_ORDER: "PAID",
  APPROVE_FOC_ORDER: "FOC",
  REJECT_FOC_ORDER: "FOC",
  CANCEL_FOC_ORDER: "FOC",
  CLOSE_FOC_ORDER: "FOC",
  DIGITIZE_FOC_ORDER: "FOC",
}

export const ACTIONS_MODAL_TITLES_MAPPING = {
  APPROVE_MAPPED_PROGRAM: ({ patientName = "", programName = "" }) =>
    `Approve ${patientName}'s ${programName} application?`,
  REJECT_MAPPED_PROGRAM: ({ patientName = "", programName = "" }) =>
    `Reject ${patientName}'s ${programName} application?`,
  APPROVE_PAID_ORDER: ({ orderId = "" }) => `Approve Paid Order ID:${orderId}?`,
  REJECT_PAID_ORDER: ({ orderId = "" }) => `Reject Paid Order ID:${orderId}?`,
  CLOSE_PAID_ORDER: ({ orderId = "" }) => `Close Paid Order ID:${orderId}?`,
  CANCEL_PAID_ORDER: ({ orderId = "" }) => `Cancel Paid Order ID:${orderId}?`,
  APPROVE_FOC_ORDER: ({ orderId = "" }) => `Approve FOC Order ID:${orderId}?`,
  REJECT_FOC_ORDER: ({ orderId = "" }) => `Reject FOC Order ID:${orderId}?`,
  CANCEL_FOC_ORDER: ({ orderId = "" }) => `Cancel FOC Order ID:${orderId}?`,
  CLOSE_FOC_ORDER: ({ orderId = "" }) => `Close FOC Order ID:${orderId}?`,
}

export const ACTIONS_MODAL_BUTTONS_MAPPING = {
  APPROVE_MAPPED_PROGRAM: { okText: "Approve Application", cancelText: "Cancel" },
  REJECT_MAPPED_PROGRAM: { okText: "Reject Application", cancelText: "Cancel" },
  APPROVE_PAID_ORDER: { okText: "Approve Paid Order", cancelText: "Cancel" },
  REJECT_PAID_ORDER: { okText: "Reject Paid Order", cancelText: "Cancel" },
  CLOSE_PAID_ORDER: { okText: "Close Paid Order", cancelText: "Cancel" },
  CANCEL_PAID_ORDER: { okText: "Cancel Paid Order", cancelText: "Don't Cancel" },
  APPROVE_FOC_ORDER: { okText: "Approve FOC Order", cancelText: "Cancel" },
  REJECT_FOC_ORDER: { okText: "Reject FOC Order", cancelText: "Cancel" },
  CANCEL_FOC_ORDER: { okText: "Cancel FOC Order", cancelText: "Don't Cancel" },
  CLOSE_FOC_ORDER: { okText: "Close FOC Order", cancelText: "Cancel" },
}

export const ACTIONS_MODAL_ICON_MAPPING = {
  APPROVE_MAPPED_PROGRAM: APPROVE_ICON,
  REJECT_MAPPED_PROGRAM: REJECT_ICON,
  APPROVE_PAID_ORDER: APPROVE_ICON,
  REJECT_PAID_ORDER: REJECT_ICON,
  CLOSE_PAID_ORDER: "",
  CANCEL_PAID_ORDER: REJECT_ICON,
  APPROVE_FOC_ORDER: APPROVE_ICON,
  REJECT_FOC_ORDER: REJECT_ICON,
  CANCEL_FOC_ORDER: REJECT_ICON,
  CLOSE_FOC_ORDER: "",
}

export const ACTIONS_FORM_MAPPING = {
  APPROVE_MAPPED_PROGRAM: APPROVE_PROGRAM_FORM,
  REJECT_MAPPED_PROGRAM: REJECT_PROGRAM_FORM,
  APPROVE_PAID_ORDER: APPROVE_PAID_ORDER_FORM,
  REJECT_PAID_ORDER: REJECT_PAID_ORDER_FORM,
  CLOSE_PAID_ORDER: CLOSE_FOC_ORDER_FORM,
  CANCEL_PAID_ORDER: CANCEL_PAID_ORDER_FORM,
  APPROVE_FOC_ORDER: APPROVE_FOC_ORDER_FORM,
  REJECT_FOC_ORDER: REJECT_FOC_ORDER_FORM,
  CANCEL_FOC_ORDER: CANCEL_FOC_ORDER_FORM,
  CLOSE_FOC_ORDER: CLOSE_FOC_ORDER_FORM,
}

export const getModalTitle = (details) => {
  const { action = "" } = details
  const getTitle = ACTIONS_MODAL_TITLES_MAPPING?.[action] || (() => {})
  const modalTitle = getTitle(details)
  return modalTitle
}

export const getModalIcon = (details) => {
  const { action = "" } = details
  const modalIcon = ACTIONS_MODAL_ICON_MAPPING?.[action] || ""
  return modalIcon
}

export const getButtonsDetails = (details) => {
  const { action = "" } = details
  const buttonDetails = ACTIONS_MODAL_BUTTONS_MAPPING?.[action] || ""
  return buttonDetails
}

export const getModalFormFields = (details) => {
  const { action = "" } = details
  const formDetails = ACTIONS_FORM_MAPPING?.[action] || ""
  return formDetails
}

export const getActionType = (details) => {
  const { action = "" } = details
  const actionType = ACTIONS_BTNS_MAPPING?.[action] || ""
  return actionType
}

export const getOrderType = (details) => {
  const { action = "" } = details
  const orderType = ACTIONS_ORDER_TYPE_MAPPING?.[action] || ""
  return orderType
}

export const getModalDetails = (details) => ({
  title: getModalTitle(details),
  icon: getModalIcon(details),
  buttons: getButtonsDetails(details),
  formDetails: getModalFormFields(details),
  actionType: getActionType(details),
  orderType: getOrderType(details),
})
