import React, { useState } from "react"
import { Button, Modal, Row, Col } from "antd"
import { useSelector } from "react-redux"
import { LabOrderModalContainer } from "../LabOrderModalContainer"
import { StatusTag } from "../StatusTag/OrderStatusTag"
import ScheduleSamplePickupForm from "../ScheduleSamplePickupForm"
import {
  canApproveLabOrder,
  canCloseLabOrder,
  canRejectLabOrder,
  canScheduleSamplePickup,
  getPermissions,
} from "../../../utils/common"

export const ActionForLabOrder = (props = {}) => {
  const {
    record = {},
    initiateSamplePickupFlow = null,
    programId,
    afterActionCallBack = () => {},
    patientId,
    source = "",
  } = props
  const programApplicationId = record?.patient_program_application_id || ""
  const permissions = useSelector(getPermissions)
  const canApproveLabTestOrder = useSelector(canApproveLabOrder)
  const canRejectLabTestOrder = useSelector(canRejectLabOrder)
  const canScheduleSamplePickUp = useSelector(canScheduleSamplePickup)
  const canCloseLabTestOrder = useSelector(canCloseLabOrder)
  const displayActionsButton =
    canApproveLabTestOrder || canRejectLabTestOrder || canScheduleSamplePickUp || canCloseLabTestOrder
  const [showScheduleSamplePickup, setShowScheduleSamplePickup] = useState(false)
  const getApproveModal = () => (
    <LabOrderModalContainer
      btnClass="lab-order-table-action-cta-styling "
      btnText="APPROVE"
      actionType="APPROVE"
      dataObj={record}
      ppaId={programApplicationId}
      programId={programId}
      updateLabOrderTable={afterActionCallBack}
      patientId={patientId}
    />
  )

  const getRejectModal = () => (
    <LabOrderModalContainer
      btnClass="lab-order-table-action-cta-styling "
      btnText="REJECT"
      actionType="REJECT"
      dataObj={record}
      ppaId={programApplicationId}
      programId={programId}
      updateLabOrderTable={afterActionCallBack}
      patientId={patientId}
    />
  )

  const samplePickupFormForLabOrder = () => (
    <Modal
      className="sample-pickup-modal"
      bodyStyle={{ height: "90vh", paddingBottom: 0, overflowY: "hidden" }}
      width={900}
      open={showScheduleSamplePickup}
      centered
      onCancel={() => {
        setShowScheduleSamplePickup(false)
      }}
      footer={null}
    >
      <Row className="h-100">
        <Col span={12}>
          <ScheduleSamplePickupForm
            title="Schedule Sample Pickup"
            programId={programId}
            patientId={patientId}
            orderId={record?.id || record?.order_id}
            patientProgramApplicationId={programApplicationId}
            record={record}
            toggleScheduleSamplePickupFormFlag={() => {
              setShowScheduleSamplePickup(false)
              afterActionCallBack()
            }}
          />
        </Col>
      </Row>
    </Modal>
  )

  const SamplePickUpOnclickHandler = () => {
    if (source === "profile") {
      initiateSamplePickupFlow(record)
    } else {
      setShowScheduleSamplePickup(true)
    }
  }

  const getScheduleSampleBtn = () => (
    <Button className="lab-order-table-action-cta-styling " onClick={SamplePickUpOnclickHandler}>
      SCHEDULE SAMPLE PICKUP
      {showScheduleSamplePickup && samplePickupFormForLabOrder()}
    </Button>
  )

  const getCloseModal = () => (
    <LabOrderModalContainer
      btnClass="lab-order-table-action-cta-styling "
      btnText="CLOSE"
      actionType="CLOSE"
      dataObj={record}
      ppaId={programApplicationId}
      programId={programId}
      updateLabOrderTable={afterActionCallBack}
      patientId={patientId}
    />
  )

  const getNextAvailableActions = () => {
    const actions = record?.next_available_actions?.filter((action) => permissions[action] || false)
    const actionRenderMap = {
      APPROVE_LAB_ORDER: getApproveModal,
      REJECT_LAB_ORDER: getRejectModal,
      SCHEDULE_SAMPLE_PICKUP: getScheduleSampleBtn,
      CLOSE_LAB_ORDER: getCloseModal,
    }
    return actions?.map((action) => {
      const actionMap = actionRenderMap[action]
      return actionMap(record)
    })
  }
  if (!displayActionsButton) return null
  return (
    <div>
      {getNextAvailableActions()}
      {canApproveLabTestOrder && record?.status === "SAMPLE PICKUP SCHEDULED" && getApproveModal()}
      {canRejectLabTestOrder && record?.status === "SAMPLE PICKUP SCHEDULED" && getRejectModal()}
      {record?.status === "REJECTED" ||
        (record?.status === "CLOSED" && <StatusTag text={record?.status || ""} />)}
      {canScheduleSamplePickUp && record?.status === "OPEN" && getScheduleSampleBtn()}
      {canCloseLabTestOrder && record?.status === "APPROVED" && getCloseModal()}
    </div>
  )
}
