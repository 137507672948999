import { Button, Tooltip, notification } from "antd"
import { useState } from "react"
import { ModalTemplate } from "../ActionModal/ModalTemplate"
import { DEFAULT_MODAL_WIDTH } from "../../../constants/common"
import { labOrderFormUrl, updateOrderStatus } from "../../../api/endpoints"
import { useMutateData } from "../../../utils/hooks"
import { Loader } from "../../Loader"

const actionTypeMap = {
  CLOSE: "CLOSE_LAB_ORDER",
  APPROVE: "APPROVE_LAB_ORDER",
  REJECT: "REJECT_LAB_ORDER",
}

const actionWithFields = ["CLOSE", "APPROVE", "REJECT"]

export const LabOrderModalContainer = (props) => {
  const {
    disableBtn,
    btnText,
    btnClass,
    showInfoOnHover = false,
    hoverText = "",
    actionType = null,
    dataObj = null,
    programId,
    updateLabOrderTable = null,
    patientId = "",
  } = props
  const [callApi, { loading }] = useMutateData()
  const [showModal, setShowModal] = useState(false)
  const [loader, setLoader] = useState(false)
  const [formFields, setFormFields] = useState({})
  const [apiCall] = useMutateData()
  const [toastMessage, contextHolder] = notification.useNotification()

  const hideModal = () => {
    setShowModal(false)
  }
  const showLoader = () => {
    setLoader(true)
  }
  const hideLoader = () => {
    setLoader(false)
  }

  const fetchFormFields = async () => {
    try {
      const { data } = await callApi({
        url: labOrderFormUrl(),
        payload: { program_id: programId, type: actionTypeMap[actionType], patient_id: patientId },
        method: "POST",
      })
      setFormFields(data?.requirements)
      setShowModal(true)
    } catch (err) {
      console.log(err)
    }
  }

  const openModal = () => {
    if (actionType && actionWithFields.includes(actionType)) {
      fetchFormFields()
    } else {
      setShowModal(true)
    }
  }

  const preparePayload = (formDataObj) => {
    const approveRejectObj =
      actionType === "APPROVE" || actionType === "REJECT"
        ? {
            is_approved: actionType === "APPROVE",
          }
        : {}
    let formattedData = {
      order_details: {
        order_id: dataObj?.id || dataObj?.order_id,
        ...approveRejectObj,
      },
      program_id: programId,
      source: "labOrder",
    }
    if (actionWithFields.includes(actionType)) {
      formattedData = {
        ...formattedData,
        action: actionType,
        order_details: {
          ...formattedData.order_details,
          ...formDataObj,
        },
      }
    } else {
      formattedData = {
        ...formattedData,
        action: actionType,
        order_details: {
          ...formattedData.order_details,
        },
      }
    }

    return formattedData
  }

  const updateLabOrderStatus = async (payload) => {
    try {
      const response = await apiCall({
        url: updateOrderStatus(),
        method: "POST",
        payload,
      })
      const { data = {} } = response
      if (data?.error) {
        hideLoader()
        toastMessage.error({
          key: "labOrderUpdateError",
          message: "Error",
          description: data?.message,
        })
      } else {
        toastMessage.success({
          key: "labOrderUpdateSuccess",
          message: "Success",
          description: data?.message,
        })
        setTimeout(() => {
          hideLoader()
          hideModal()
          updateLabOrderTable()
        }, 1000)
      }
    } catch (err) {
      console.log("lab order update error")
    }
  }

  const receiveData = (formData) => {
    showLoader()
    const payload = preparePayload(formData?.formData?.order_details)
    updateLabOrderStatus(payload)
  }

  const renderModal = () => (
    <ModalTemplate
      hideModal={hideModal}
      open={showModal}
      icon=""
      modalTitle={`Are you sure you want to ${actionType} this order ?`}
      okText="Ok"
      cancelText="Cancel"
      actionType=""
      onOk={receiveData}
      width={DEFAULT_MODAL_WIDTH}
      formDetails={formFields}
    />
  )

  const buttonContent = (
    <Button disabled={disableBtn} className={btnClass} onClick={() => openModal()}>
      <span>{btnText}</span>
    </Button>
  )

  return (
    <>
      {contextHolder}
      {(loader || loading) && <Loader size={60} type="semiTransparentFullPage" />}
      {showModal && renderModal()}
      {showInfoOnHover ? <Tooltip title={hoverText}>{buttonContent}</Tooltip> : buttonContent}
    </>
  )
}
