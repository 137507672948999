import Paragraph from "antd/lib/typography/Paragraph"
import { Link } from "react-router-dom"
import { formatDate } from "../../../utils/common"
import { StatusTag } from "../StatusTag/OrderStatusTag"
import { PresignedUrlFileIcon } from "../PresignedUrlFileIcon"
import { ActionsForOrderTable } from "../ActionsForOrdersTable"
import { DEFAULT_INPUT_MAX_LIMIT } from "../DynamicForm/FormSectionBuilder/constants"

export const defaultOrdersTableRenderFunction = (value = "") => (
  <Paragraph ellipsis={{ rows: 1, expandable: false, symbol: "..", tooltip: true, suffix: "" }}>
    {value}
  </Paragraph>
)

export const getSku = (record) => record?.sku || record?.order_details?.sku || ""

export const getQuantity = (record) =>
  record?.quantity ||
  record?.sku_selection?.number_of_boxes ||
  record?.sku_selection?.quantity ||
  record?.order_details?.quantity ||
  record?.order_details?.number_of_boxes ||
  ""

export const getStatus = (record) => <StatusTag text={record?.status || ""} />

export const getPaidOrderInvoice = (record) => {
  const documentDetails = record?.invoice_file || record?.order_details?.invoice_upload || ""
  const fileKey = documentDetails?.file_key || ""
  const documentId = documentDetails?.document_id || ""
  const fileUrl = documentDetails
  const document = { fileKey, documentId, fileUrl }
  return <PresignedUrlFileIcon document={document} />
}

export const getPaidOrderInvoiceDate = (record) => {
  const dateVal = record?.invoice_date || record?.order_details?.invoice_date || ""
  return dateVal ? formatDate(dateVal) : ""
}

const knownDateColumns = [
  "created_on",
  "updated_on",
  "cancellation_date",
  "closure_date",
  "approval_date",
  "zero_value_invoice_date",
  "invoice_date",
]

export const getSearchConfig = ({ column = "", columnLabel = "" }) => {
  let searchConfig = {}
  let fieldType = "text"
  const isIdColumn = column?.indexOf("_id") !== -1
  const alphanumbericFields = ["sku_name", "patient_uid"]
  const isAlphaNumeric = alphanumbericFields?.indexOf(column) !== -1
  const isNumberColumn = column?.indexOf("_number") !== -1 || ["urn"]?.indexOf(column) !== -1
  const isNumbersOnlyColumn = isIdColumn || isNumberColumn
  let additionalConfig = ""
  additionalConfig = {}
  if (isNumbersOnlyColumn) {
    fieldType = "number"
  }
  if (isAlphaNumeric) {
    fieldType = "alphanumeric"
  }
  if (column === "patient_name") {
    fieldType = "freetext"
    additionalConfig = {
      regex: `^[A-Za-z. ]+$`,
      regexMessage: "Only Alphabets and . are allowed",
      maxLength: DEFAULT_INPUT_MAX_LIMIT,
    }
  }
  searchConfig = {
    step_name: column,
    step_name_text: columnLabel,
    field_type: fieldType,
    ...additionalConfig,
  }
  return searchConfig
}

export const formtDashboardColumns = ({ columns = [], currentSubdomain = "" }) => {
  const formattedColumns = columns.map((column) => {
    let formattedColumn = {}
    formattedColumn = { ...column }
    const { key = "", renderFn = "" } = column
    formattedColumn = {
      ...formattedColumn,
      render: (_, record) => (
        <Paragraph ellipsis={{ rows: 1, expandable: false, symbol: "..", tooltip: true, suffix: "" }}>
          {record[column] || ""}
        </Paragraph>
      ),
    }
    if (renderFn === "statusTag") {
      formattedColumn = {
        ...formattedColumn,
        render: (_, record) => getStatus(record),
      }
    }
    if (renderFn === "file") {
      formattedColumn = {
        ...formattedColumn,
        render: (_, record) => {
          const fileId = record[key] || ""
          let fileColumnRender = ""
          if (fileId) {
            fileColumnRender = <PresignedUrlFileIcon documentId={fileId} showLoader />
          }
          return fileColumnRender
        },
      }
    }
    if (renderFn === "booleanToYesNo") {
      formattedColumn = {
        ...formattedColumn,
        render: (_, record) => (record[key] ? "Yes" : "No"),
      }
    }
    if (key === "order_id") {
      formattedColumn = {
        ...formattedColumn,
        render: (value, record) => (
          <Link
            className="internal-link"
            to={`/${currentSubdomain}/patient/view-program/${
              record?.patient_id || record?.id || record?.uid
            }/${record?.program_id}`}
          >
            {value}
          </Link>
        ),
      }
    }
    return formattedColumn
  })
  return formattedColumns
}

export const formatOrdersTableColumn = (column, currentSubdomain = "") => {
  const fileColumns = [
    "invoice_upload",
    "infusion_summary",
    "purchase_invoice",
    "purachase_invoice",
    "empty_strip_image",
    "invoice",
    "rx_file",
    "invoice_file",
  ]
  // const isDateColumn = column?.indexOf("_date") !== -1
  const customColumnLabels = {
    sku_name: "SKU Name",
    urn: "URN",
  }
  const searchDisabledColumns = ["quantity", "rx_file", "invoice_file", ...knownDateColumns, ...fileColumns]
  const booleanColumns = ["valid_rx", "dose_to_be_administered"]
  const isFileColumn = fileColumns.includes(column)
  const isBooleanColumn = booleanColumns.includes(column)
  const disableSearch = searchDisabledColumns.includes(column)
  let formattedColumn = {}
  const columnLabel = customColumnLabels[column] || column.replaceAll("_", " ")
  const searchConfig = getSearchConfig({ column, columnLabel })
  formattedColumn = {
    key: column,
    label: columnLabel,
    disableSearch,
    searchConfig,
  }
  formattedColumn = {
    ...formattedColumn,
    render: (_, record) => (
      <Paragraph ellipsis={{ rows: 1, expandable: false, symbol: "..", tooltip: true, suffix: "" }}>
        {record[column] || ""}
      </Paragraph>
    ),
  }
  if (column === "status") {
    formattedColumn = {
      ...formattedColumn,
      render: (_, record) => getStatus(record),
    }
  }
  if (isFileColumn) {
    formattedColumn = {
      ...formattedColumn,
      render: (_, record) => {
        const fileId = record[column] || ""
        let fileColumnRender = ""
        if (fileId) {
          fileColumnRender = <PresignedUrlFileIcon documentId={fileId} showLoader />
        }
        return fileColumnRender
      },
    }
  }
  if (isBooleanColumn) {
    formattedColumn = {
      ...formattedColumn,
      render: (_, record) => (record[column] ? "Yes" : "No"),
    }
  }
  if (currentSubdomain && column === "order_id") {
    formattedColumn = {
      ...formattedColumn,
      render: (_, record) => (
        <Link to={`/${currentSubdomain}/patient/view-program/${record.patient_id}/${record.program_id}`}>
          {record[column]}
        </Link>
      ),
    }
  }
  return formattedColumn
}

export const getDynamicColumns = ({
  columns = [],
  orderType,
  afterActionCallback,
  updateTags = null,
  programId,
  currentSubdomain,
}) => {
  let dynamicColumns = []
  const firstColumn = columns.length > 0 ? columns[0] : null
  const isSimpleList = typeof firstColumn === "string"
  dynamicColumns = isSimpleList
    ? columns?.map((column) => formatOrdersTableColumn(column, currentSubdomain))
    : formtDashboardColumns({ columns, currentSubdomain })
  dynamicColumns[dynamicColumns.length] = {
    label: "Actions",
    render: (_, record) => (
      <ActionsForOrderTable
        orderId={record?.order_id || ""}
        orderStatus={`${orderType}_${record?.status?.toUpperCase() || ""}`}
        orderType={`${orderType}`}
        patientProgramApplicationId={record?.patient_program_application_id || ""}
        afterActionCallback={afterActionCallback}
        updateTags={updateTags}
        programId={programId}
      />
    ),
  }
  return dynamicColumns
}

export const getProfilePaidOrdersColumns = ({
  columns = [],
  afterActionCallback,
  programId,
  currentSubdomain,
}) => getDynamicColumns({ columns, orderType: "PAID", afterActionCallback, programId, currentSubdomain })

export const getProfileFocOrdersColumns = ({
  columns = [],
  afterActionCallback,
  updateTags = null,
  programId,
  currentSubdomain,
}) =>
  getDynamicColumns({
    columns,
    orderType: "FOC",
    afterActionCallback,
    updateTags,
    programId,
    currentSubdomain,
  })

export const getOrdersDashboardColumns = ({ columns = [], currentSubdomain = "" }) => {
  const firstColumn = columns.length > 0 ? columns[0] : null
  const isSimpleList = typeof firstColumn === "string"
  let dynamicColumns = []
  dynamicColumns = isSimpleList
    ? columns?.map((column) => formatOrdersTableColumn(column, currentSubdomain))
    : formtDashboardColumns({ columns, currentSubdomain })
  return dynamicColumns
}
export const PROFILE_FOC_ORDERS_COLUMNS = [
  {
    key: "order_id",
    label: "ID",
  },
  {
    key: "order_date",
    label: "Order Date",
    render: (_, record) => {
      const orderDate = record?.order_date || ""
      return orderDate ? formatDate(orderDate) : ""
    },
  },
  {
    key: "status",
    label: "Status",
    render: (_, record) => getStatus(record),
  },
  {
    key: "sku",
    label: "SKU",
  },
  {
    key: "quantity",
    label: "Quantity",
  },
  {
    label: "Invoice",
    key: "invoice",
    render: (_, record) => {
      const fileId = record?.invoice || ""
      return <PresignedUrlFileIcon documentId={fileId} />
    },
  },
  {
    key: "distributor",
    label: "Distributor",
  },
  {
    key: "notes",
    label: "Notes",
  },
  {
    key: "approved_date",
    label: "Approved Date",
    render: (_, record) => {
      const approvedOn = record?.approved_date || ""
      return approvedOn ? formatDate(approvedOn) : ""
    },
  },
  {
    key: "cancellation_date",
    label: "Cancelled Date",
    render: (_, record) => {
      const cancelledOn = record?.cancellation_date || ""
      return cancelledOn ? formatDate(cancelledOn) : ""
    },
  },
  {
    label: "Actions",
    render: (_, record) => (
      <ActionsForOrderTable
        orderId={record?.order_id || ""}
        orderStatus={`FOC_${record?.status?.toUpperCase() || ""}`}
        orderType="FOC"
        patientProgramApplicationId={record?.patient_program_application_id || ""}
        programId={record?.program_id || ""}
      />
    ),
  },
]

export const PROFILE_PAID_ORDERS_COLUMNS = [
  {
    key: "order_id",
    label: "ID",
  },
  {
    key: "order_date",
    label: "Order Date",
    render: (_, record) => {
      const orderDate = record?.order_date || ""
      return orderDate ? formatDate(orderDate) : ""
    },
  },
  {
    key: "status",
    label: "Status",
    render: (_, record) => getStatus(record),
  },
  {
    key: "sku",
    label: "SKU",
  },
  {
    key: "quantity",
    label: "Quantity",
  },
  {
    label: "Invoice Date",
    key: "invoice_date",
    render: (_, record) => {
      const invoiceDate = record?.invoice_date || ""
      return invoiceDate ? formatDate(invoiceDate) : ""
    },
  },
  {
    label: "Invoice",
    key: "invoice_upload",
    render: (_, record) => {
      const fileId = record?.invoice_upload || ""
      return <PresignedUrlFileIcon documentId={fileId} />
    },
  },
  {
    key: "distributor",
    label: "Distributor",
  },
  {
    key: "notes",
    label: "Notes",
  },
  {
    key: "approved_date",
    label: "Approved Date",
    render: (_, record) => {
      const approvedOn = record?.approved_date || ""
      return approvedOn ? formatDate(approvedOn) : ""
    },
  },
  {
    key: "cancelled_date",
    label: "Cancelled Date",
    render: (_, record) => {
      const cancelledOn = record?.cancelled_date || ""
      return cancelledOn ? formatDate(cancelledOn) : ""
    },
  },
  {
    label: "Actions",
    render: (_, record) => (
      <ActionsForOrderTable
        orderId={record?.order_id || ""}
        orderStatus={`PAID_${record?.status?.toUpperCase() || ""}`}
        orderType="PAID"
        patientProgramApplicationId={record?.patient_program_application_id || ""}
        programId={record?.program_id || ""}
      />
    ),
  },
]

export const DASHBOARD_FOC_ORDERS_COLUMNS = [
  {
    key: "order_id",
    label: "ID",
    searchConfig: {
      step_name: "order_id",
      step_name_text: "ID",
      field_type: "number",
    },
  },
  {
    key: "patient_id",
    label: "Patient ID",
    searchConfig: {
      step_name: "patient_id",
      step_name_text: "Patient ID",
      field_type: "number",
    },
  },
  {
    key: "patient_name",
    label: "Patient Name",
    searchConfig: {
      step_name: "patient_name",
      step_name_text: "Patient Name",
      field_type: "text",
    },
  },
  {
    key: "distributor_name",
    label: "Distributor",
    render: (_, record) => record?.distributor_name || "",
    searchConfig: {
      step_name: "distributor_name",
      step_name_text: "Distributor Name",
      field_type: "text",
    },
  },
  {
    key: "status",
    label: "Status",
    render: (_, record) => getStatus(record),
    disableSearch: true,
  },
  {
    key: "created_on",
    label: "Created On",
    render: (_, record) => (record?.created_on ? formatDate(record.created_on) : ""),
    disableSearch: true,
  },
]

export const DASHBOARD_PAID_ORDERS_COLUMNS = [
  {
    key: "order_id",
    label: "ID",
    searchConfig: {
      step_name: "order_id",
      step_name_text: "ID",
      field_type: "number",
    },
  },
  {
    key: "patient_id",
    label: "Patient ID",
    searchConfig: {
      step_name: "patient_id",
      step_name_text: "Patient ID",
      field_type: "number",
    },
  },
  {
    key: "patient_name",
    label: "Patient Name",
    searchConfig: {
      step_name: "patient_name",
      step_name_text: "Patient Name",
      field_type: "text",
    },
  },
  {
    label: "SKU",
    render: (_, record) => getSku(record),
    key: "sku",
  },
  {
    label: "Distributor",
    render: (_, record) => record?.distributor_name || "",
    key: "distributor_name",
  },
  {
    key: "status",
    label: "Status",
    render: (_, record) => getStatus(record),
    disableSearch: true,
  },
  {
    key: "created_on",
    label: "Created On",
    render: (_, record) => (record?.created_on ? formatDate(record.created_on) : ""),
    disableSearch: true,
  },
]
