import { Button, notification } from "antd"
import { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { func, string } from "prop-types"
import { getPermissions } from "../../../utils/common"
import { useMutateData } from "../../../utils/hooks"
import { getCreateOrderFormApi, updateOrderStatus } from "../../../api/endpoints"
import { NEXT_AVAILABLE_ACTIONS, ACTIONS_BTNS_MAPPING, getModalDetails } from "./constants"
import { ModalTemplate } from "../ActionModal/ModalTemplate"
import { Loader } from "../../Loader"
import "./styles.scss"
import { OrderDigitisation } from "./OrderDigitisation"
import { getFetchFormPayload } from "../CreateOrderForm/constants"
import { getDropdowns } from "../DynamicForm/constants"

export const ActionsForOrderTable = (props) => {
  const {
    orderStatus = "",
    orderId = "",
    orderType = "",
    patientProgramApplicationId = "",
    afterActionCallback,
    updateTags = null,
    programId = "",
    patientId = "",
  } = props
  const [apiCall, { loading }] = useMutateData()
  const permissions = useSelector(getPermissions)
  const [toastMessage, contextHolder] = notification.useNotification()
  const [showModal, setShowModal] = useState(false)
  const [modalFields, setModalFields] = useState({})
  const [modalDetails, setModalDetails] = useState({})
  const openModal = () => setShowModal(true)
  const hideModal = () => {
    setShowModal(false)
    setModalDetails({})
    setModalFields({})
  }
  const actions =
    NEXT_AVAILABLE_ACTIONS?.[orderStatus]?.filter((action) => permissions[action] || false) || []
  const fetchModalFields = async ({ action = "" }) => {
    const payload = getFetchFormPayload({ patientId, programId, action })
    const { data = {} } = await apiCall({
      url: getCreateOrderFormApi,
      method: "POST",
      payload,
    })
    const { error = false } = data
    if (!error) {
      const modalFormFields = {
        ...data,
        dropdowns: getDropdowns(data?.requirements || {}),
      }
      setModalFields(modalFormFields)
    }
  }
  const fetchModalDetails = (details) => {
    fetchModalFields(details)
    const modalParams = getModalDetails(details)
    if (JSON.stringify(modalDetails) !== JSON.stringify(modalParams)) {
      setModalDetails(modalParams)
    }
  }
  const getDefaultActionButton = ({ action }) => {
    const actionBtnText = ACTIONS_BTNS_MAPPING?.[action] || ""
    return (
      <Button
        className="orderTableActionButtons"
        onClick={() => {
          fetchModalDetails({ action, orderId })
        }}
      >
        {actionBtnText?.toLowerCase() || ""}
      </Button>
    )
  }
  const getDigitiseActionButton = ({ action }) => (
    <OrderDigitisation
      action={action}
      orderId={orderId}
      programId={programId}
      afterActionCallback={afterActionCallback}
      patientId={patientId}
      programApplicationId={patientProgramApplicationId}
    />
  )
  const getButton = ({ action }) => {
    let actionButton = ""
    if (action === "DIGITIZE_PAID_ORDER" || action === "DIGITIZE_FOC_ORDER") {
      actionButton = getDigitiseActionButton({ action })
    } else {
      actionButton = getDefaultActionButton({ action })
    }
    return actionButton
  }
  const getAllActionButtons = () => actions.map((action) => getButton({ action }))
  const allActionButtons = getAllActionButtons()
  const showActionMessage = (data) => {
    const { error = false, message = "" } = data
    if (!error) {
      toastMessage.success({
        key: "orderStatusUpdateSuccess",
        message: "Success",
        description: message,
      })
    }
  }
  const actionCall = async (details) => {
    const { action = "", formData = {} } = details
    if (action !== "Close") {
      try {
        const { data = {} } = await apiCall({
          url: updateOrderStatus(),
          method: "POST",
          payload: {
            order_id: orderId,
            program_id: programId,
            patient_program_application_id: patientProgramApplicationId,
            type: orderType,
            action,
            ...formData,
          },
        })
        if (data?.tag && Object.keys(data.tag).length > 0 && updateTags) {
          updateTags(data.tag)
        }
        showActionMessage(data)
        hideModal()
        afterActionCallback()
      } catch (err) {
        showActionMessage(err)
        hideModal()
        afterActionCallback()
      }
    }
  }
  useEffect(() => {
    if (modalDetails?.title) {
      openModal()
    }
  }, [modalDetails?.title])
  return (
    <>
      {contextHolder}
      {loading && <Loader type="semiTransparentFullPage" size={60} />}
      {showModal && (
        <ModalTemplate
          hideModal={hideModal}
          open={showModal}
          icon={modalDetails?.icon || ""}
          modalTitle={modalDetails?.title || ""}
          okText={modalDetails?.buttons?.okText || "Ok"}
          cancelText={modalDetails?.buttons?.cancelText || "Cancel"}
          formDetails={modalFields?.requirements || {}}
          actionType={modalDetails?.actionType || ""}
          orderType={modalDetails?.orderType || ""}
          onOk={actionCall}
        />
      )}
      {allActionButtons}
    </>
  )
}

ActionsForOrderTable.defaultProps = {
  orderStatus: "",
  orderId: "",
  orderType: "",
  patientProgramApplicationId: "",
  afterActionCallback: () => {},
}

ActionsForOrderTable.propTypes = {
  orderStatus: string,
  orderId: string,
  orderType: string,
  patientProgramApplicationId: string,
  afterActionCallback: func,
}
