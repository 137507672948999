import { useState, useMemo, useEffect } from "react"
import { useSelector } from "react-redux"
import { Col, Row } from "antd"
import { useMutateData } from "../../../utils/hooks"
import { labDataURL, getPatientLabOrderDataUrl } from "../../../api/endpoints/labOrders"
import { DataTableWithPagination } from "../DataTableWithPagination"
import {
  canApproveLabOrder,
  canCloseLabOrder,
  canRejectLabOrder,
  canScheduleSamplePickup,
} from "../../../utils/common"
import "./styles.scss"
import { ExportButtonForDashboard } from "../ExportButtonForDashboard"
import { PAID_ORDER_ICON } from "../../../constants/images"
import { useColumnsFormatting } from "../DataTableWithPagination/useColumnsFormatting"
import { ActionForLabOrder } from "../ActionsForLabOrder"
import { defaultOrdersTableRenderFunction } from "../OrdersTable/constants"

export const LabOrdersDashboard = (props) => {
  const {
    source = "dashboard",
    programId = null,
    programApplicationId = null,
    toggleScheduleSamplePickupFormFlag = null,
    patientId = null,
    refreshProgramDetailsSection = () => {},
  } = props
  const [labData, setLabData] = useState([])
  const [getFormattedColumns] = useColumnsFormatting({
    customDefaultRenderFn: defaultOrdersTableRenderFunction,
  })
  const [otherParams, setOtherParams] = useState({})
  const memorisedOtherParams = useMemo(() => otherParams, [otherParams])
  const [callApi, { loading }] = useMutateData()
  const [labDashboardColumns, setLabDashboardColumns] = useState({})
  const [enableExportFunctionality] = useState(source !== "profile")
  const canApproveLabTestOrder = useSelector(canApproveLabOrder)
  const canRejectLabTestOrder = useSelector(canRejectLabOrder)
  const canScheduleSamplePickUp = useSelector(canScheduleSamplePickup)
  const canCloseLabTestOrder = useSelector(canCloseLabOrder)
  const displayActionsColumn =
    canApproveLabTestOrder || canRejectLabTestOrder || canScheduleSamplePickUp || canCloseLabTestOrder

  const fetchLabOrdersData = async (params = {}) => {
    let payload = {}
    payload = {
      page_no: params?.page_number || 0,
      page_size: params?.page_size || 10,
      application_id: params?.application_id || 1,
      ...params,
    }
    if (source === "profile") {
      payload.program_id = programId
      delete payload.page_no
      delete payload.page_size
      delete payload.application_id
      payload.patient_program_application_id = programApplicationId
    }
    delete payload.page_number
    try {
      const queryParamString = new URLSearchParams(payload)
      let url = labDataURL
      let method = "POST"
      let apiCallOptions = { url, method, payload }
      if (source === "profile") {
        url = getPatientLabOrderDataUrl({ queryParamString })
        method = "GET"
        apiCallOptions = { url, method }
      }
      const { data = {} } = await callApi(apiCallOptions)
      setLabData(data)
      const dynamicColumnsObj = getFormattedColumns({ columns: data?.display_columns || [] })
      setLabDashboardColumns(dynamicColumnsObj)
    } catch (apiError) {
      console.log("Lab Dashboard Datatable error", apiError)
    }
  }

  const refreshPage = (params = {}) => {
    refreshProgramDetailsSection()
    fetchLabOrdersData(params)
  }

  const initiateSamplePickupFlow = (record) => {
    toggleScheduleSamplePickupFormFlag(true, record)
  }
  const addActionsColumn = () => {
    const actionsColumnExists = labDashboardColumns.some((column) => column.key === "actions")
    if (!actionsColumnExists && displayActionsColumn) {
      const tableColumnsTemp = [...labDashboardColumns]
      tableColumnsTemp.push({
        id: tableColumnsTemp.length + 1,
        key: "actions",
        label: "Actions",
        disableSearch: true,
        render: (_, record) => (
          <ActionForLabOrder
            record={record}
            initiateSamplePickupFlow={initiateSamplePickupFlow}
            afterActionCallBack={refreshPage}
            source="profile"
            patientId={patientId}
            programId={programId}
          />
        ),
      })

      setLabDashboardColumns(tableColumnsTemp)
    }
  }

  useEffect(() => {
    if (source === "profile" && labDashboardColumns && labDashboardColumns.length > 0) {
      addActionsColumn()
    }
  }, [labDashboardColumns])

  return (
    <div className="lab-dashboard-container ">
      <Row className="white-bg lab-dashboard-table-container">
        {enableExportFunctionality && (
          <ExportButtonForDashboard tooltipText="Export Lab Orders" table="lab_order_data" />
        )}
        <Row align="middle">
          <Col span={1}>
            <img src={PAID_ORDER_ICON} className="program-details-section-icon" alt="" />
          </Col>
          <Col span={3} className="program-details-section-name">
            Lab Orders
          </Col>
        </Row>
        <Row className="lab-dashboard-table-data-row ">
          <DataTableWithPagination
            data={labData?.orders || labData?.order_data || []}
            totalDataCount={labData?.total_count ? labData.total_count : 0}
            columns={labDashboardColumns}
            api={fetchLabOrdersData}
            loading={loading}
            otherParams={memorisedOtherParams}
            setOtherParams={setOtherParams}
            disableSticky
          />
        </Row>
      </Row>
    </div>
  )
}
