export const dateFields = ["sample_pickup_date"]
export const timeFields = ["sample_pickup_time"]
export const NUMBER_OF_HOURS_IN_HALF_A_DAY = 12

export const formatTimeTo12Hour = (inputTime) => {
  const [hoursStr, minutesStr] = inputTime.split(":")
  const hours = parseInt(hoursStr, 10)
  const minutes = parseInt(minutesStr, 10)
  const period = hours >= NUMBER_OF_HOURS_IN_HALF_A_DAY ? "PM" : "AM"

  const formattedHours = hours % NUMBER_OF_HOURS_IN_HALF_A_DAY || NUMBER_OF_HOURS_IN_HALF_A_DAY
  return `${formattedHours}:${minutes.toString().padStart(2, "0")} ${period}`
}

export const fieldRemovalDependencyObj = {
  delivery_type: {
    2: "patient_address_id",
  },
}
