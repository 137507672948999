import { useSelector } from "react-redux"
import { getCurrentSubdomain } from "../../../utils/common"
import {
  getClaimDetailsPageFromDashboardLink,
  getClaimDetailsPageFromTableLink,
  getClaimDetailsPageLink,
  getDefaultDataTableRenderFn,
  getOrderDetailsPageLink,
  getPatientProfilePageLink,
  getPatientProgramDetailsPageLink,
} from "./constants"
import { renderFunctions } from "../../../constants/render"

export const useColumnsFormatting = ({ customDefaultRenderFn = null } = {}) => {
  const currentSubdomain = useSelector(getCurrentSubdomain)
  const getRenderFunction = ({ column = {} } = {}) => {
    const { renderFn = "default" } = column
    const renderFnMap = {
      default: customDefaultRenderFn || getDefaultDataTableRenderFn,
      ...renderFunctions,
      linkToPatientProgramDetailsPage: (value, record) => {
        const patientId = record?.patient_id || record?.id || record?.uid || ""
        const programId = record?.program_id || ""
        return getPatientProgramDetailsPageLink({ currentSubdomain, patientId, programId, text: value })
      },
      linkToPatientProfilePage: (value, record) => {
        const patientId = record?.patient_id || record?.id || record?.uid || ""
        return getPatientProfilePageLink({ currentSubdomain, patientId, text: value })
      },
      linkToClaimDetailsPageFromDashboard: (value, record) => {
        const claimId = record?.id || ""
        const programId = record?.program_id || ""
        const patientId = record?.patient_id || ""
        return getClaimDetailsPageFromDashboardLink({
          currentSubdomain,
          claimId,
          programId,
          text: value,
          patientId,
        })
      },
      linkToClaimDetailsPageFromTable: (value, record) => {
        const claimId = record?.id || ""
        const programId = record?.program_id || ""
        const patientId = record?.patient_id || ""
        return getClaimDetailsPageFromTableLink({
          currentSubdomain,
          claimId,
          programId,
          text: value,
          patientId,
        })
      },
      linkToClaimDetailsPage: (value, record) => {
        const claimId = record?.id || ""
        const programId = record?.program_id || ""
        return getClaimDetailsPageLink({ currentSubdomain, claimId, programId, text: value })
      },
      linkToFocOrderDetailsPageFromDashboard: (value, record) => {
        const orderId = record?.order_id || record?.id
        const programId = record?.program_id
        return getOrderDetailsPageLink({
          type: "FOC",
          orderId,
          source: "dashboard",
          text: value,
          currentSubdomain,
          programId,
        })
      },
      linkToFocOrderDetailsPageFromTable: (value, record) => {
        const orderId = record?.order_id || record?.id
        const programId = record?.program_id
        return getOrderDetailsPageLink({
          type: "FOC",
          orderId,
          source: "profile",
          text: value,
          currentSubdomain,
          programId,
        })
      },
      linkToPaidOrderDetailsPageFromDashboard: (value, record) => {
        const orderId = record?.order_id || record?.id
        const programId = record?.program_id
        return getOrderDetailsPageLink({
          type: "PAID",
          orderId,
          source: "dashboard",
          text: value,
          currentSubdomain,
          programId,
        })
      },
      linkToPaidOrderDetailsPageFromTable: (value, record) => {
        const orderId = record?.order_id || record?.id
        const programId = record?.program_id
        return getOrderDetailsPageLink({
          type: "PAID",
          orderId,
          source: "profile",
          text: value,
          currentSubdomain,
          programId,
        })
      },
      linkToLabOrderDetailsPageFromDashboard: (value, record) => {
        const orderId = record?.order_id || record?.id
        const programId = record?.program_id
        return getOrderDetailsPageLink({
          type: "LAB",
          orderId,
          source: "dashboard",
          text: value,
          currentSubdomain,
          programId,
        })
      },
      linkToLabOrderDetailsPageFromTable: (value, record) => {
        const orderId = record?.order_id || record?.id
        const programId = record?.program_id
        return getOrderDetailsPageLink({
          type: "LAB",
          orderId,
          source: "profile",
          text: value,
          currentSubdomain,
          programId,
        })
      },
    }
    const renderFunction = renderFnMap[renderFn] || renderFnMap.default
    return renderFunction
  }
  const getFormattedColumn = ({ column = {} } = {}) => {
    const renderFunction = getRenderFunction({ column })
    const formattedColumn = {
      ...column,
      render: renderFunction,
    }
    return formattedColumn
  }
  const getFormattedColumns = ({ columns = [] } = {}) => {
    const formattedColumns = columns.map((column) => getFormattedColumn({ column }))
    return formattedColumns
  }
  return [getFormattedColumns]
}
