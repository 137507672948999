export const REJECT_PAID_ORDER_FORM = {
  sections: [
    {
      name: "details",
      label: "",
      steps: [
        {
          step_name: "rejection_notes",
          step_name_text: "Rejection Notes (optional)",
          is_mandatory: false,
          field_type: "text",
        },
        {
          step_name: "rejection_file",
          step_name_text: "Rejection File (optional)",
          is_mandatory: false,
          field_type: "file_upload",
        },
      ],
    },
  ],
}
