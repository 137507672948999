export const REJECT_PROGRAM_FORM = {
  sections: [
    {
      name: "details",
      label: "",
      steps: [
        {
          step_name: "comment",
          step_name_text: "Rejection Notes",
          is_mandatory: true,
          field_type: "freetext",
          regex: `^[A-Za-z0-9 .,|()'/&:\\\\-]+$`,
          regexMessage: "Only alphabets, numbers and [.,|()'/&:-] are allowed",
          maxLength: 200,
        },
      ],
    },
  ],
}
