import { useEffect, useState, useCallback } from "react"
import { notification } from "antd"
import { func, string } from "prop-types"
import { useMutateData } from "../../../utils/hooks"
import { getCreateOrderFormApi, getOrderOptionsApi, createOrderApi } from "../../../api/endpoints"
import { DynamicForm } from "../DynamicForm"
import { getFetchFormPayload } from "./constants"
import { getDropdowns } from "../DynamicForm/constants"

export const CreateOrderForm = (props = {}) => {
  const {
    type = "",
    patientId = "",
    programId = "",
    closeOrderForm,
    action = "CREATE",
    customSaveCallback,
    showFormFooter = true,
    formSectionBuilderWidth = 6,
    customForm,
    formName = "",
  } = props
  const [apiCall] = useMutateData()
  const [toastMessage, contextHolder] = notification.useNotification()
  const [orderForm, setOrderForm] = useState({})
  const [optionsData, setOptionsData] = useState({})
  const [formDefaultValues, setFormDefaultValues] = useState({})
  const [allSelectedDropdownValues, setAllSelectedDropdownValues] = useState({})
  const setSelectedDropdownValue = ({ name, value }) => {
    if (name) {
      let newSelectedDropdownValues = {}
      if (value) {
        newSelectedDropdownValues = {
          ...allSelectedDropdownValues,
          [name]: value,
        }
      } else {
        delete newSelectedDropdownValues[name]
      }
      if (JSON.stringify(newSelectedDropdownValues) !== JSON.stringify(allSelectedDropdownValues)) {
        setAllSelectedDropdownValues(newSelectedDropdownValues)
      }
    }
  }
  const fetchOptionsBasedOnUserSelection = async (name, value) => {
    setSelectedDropdownValue({ name, value })
  }
  const fetchOptions = useCallback(
    ({ key = "" }) =>
      new Promise((resolve) => {
        apiCall({
          url: getOrderOptionsApi(),
          method: "POST",
          payload: {
            patient_program_application_id: props?.patient_program_application_id || "",
            options_key: key,
            program_id: programId,
          },
        })
          .then(({ data = [] }) => {
            resolve(data)
          })
          .catch(() => resolve([]))
      }),
    [apiCall, props?.patient_program_application_id]
  )
  const getAllDropdownData = useCallback(
    ({ dropdownKeys = [] }) => {
      const allDropdownOptions = dropdownKeys.map((key) => fetchOptions({ key }))
      Promise.all(allDropdownOptions).then((values) => {
        let dropdownValues = ""
        dropdownValues = {}
        dropdownKeys.forEach((key, i) => {
          if (key) {
            dropdownValues[key] = values[i]
          }
        })
        setOptionsData(dropdownValues)
      })
    },
    [fetchOptions]
  )

  const fetchOrderForm = useCallback(async () => {
    const payload = getFetchFormPayload({ patientId, programId, action, orderType: type })
    const { data = {} } = await apiCall({
      url: getCreateOrderFormApi,
      method: "POST",
      payload,
    })
    const { requirements = {}, error = false, defaultValues = {} } = data
    const dropdowns = getDropdowns(requirements)
    if (!error) {
      setOrderForm(requirements)
      setFormDefaultValues(defaultValues)
      getAllDropdownData({ dropdownKeys: dropdowns })
    }
  }, [type, programId, apiCall, getAllDropdownData])

  const createOrder = async (inputData) => {
    let tempInputData = ""
    tempInputData = {
      ...inputData,
    }
    if (tempInputData?.order_details?.quantity) {
      tempInputData.order_details.quantity = Number(tempInputData.order_details.quantity)
    }
    const { data = {} } = await apiCall({
      url: createOrderApi,
      method: "POST",
      payload: {
        patient_program_application_id: props?.patient_program_application_id || "",
        order_creation_details: tempInputData,
        type,
        program_id: programId,
      },
    })
    const { error = false } = data
    if (!error) {
      toastMessage.success({
        key: "createOrderSuccess",
        message: "Success",
        description: data?.message || "",
      })
      setTimeout(() => {
        closeOrderForm()
      }, 2000)
    }
  }
  useEffect(() => {
    fetchOrderForm()
  }, [type, programId, fetchOrderForm])
  const getFormSaveCallback = () => {
    let saveCallback = createOrder
    if (customSaveCallback) {
      saveCallback = customSaveCallback
    }
    return saveCallback
  }
  const formSavecallback = getFormSaveCallback()
  return (
    <>
      {contextHolder}
      {formName && <div className="patient-program-form-title">{formName}</div>}
      <DynamicForm
        formDetails={orderForm}
        optionsData={optionsData}
        saveCallback={formSavecallback}
        savedData={formDefaultValues}
        uploadFileParams={{ patientId }}
        formType="createOrder"
        customForm={customForm}
        showFormFooter={showFormFooter}
        formSectionBuilderWidth={formSectionBuilderWidth}
        fetchOptionsBasedOnUserSelection={fetchOptionsBasedOnUserSelection}
        stepsFilterCustomSteps={allSelectedDropdownValues}
      />
    </>
  )
}

CreateOrderForm.defaultProps = {
  type: "",
  patientId: "",
  programId: "",
  closeOrderForm: () => {},
  patient_program_application_id: "",
}

CreateOrderForm.propTypes = {
  type: string,
  patientId: string,
  programId: string,
  closeOrderForm: func,
  patient_program_application_id: string,
}
